
export default {
        primary: {
            base: '#003050',
            lighten5:  '#8299AA',
            lighten4:  '#688498',
            lighten3:  '#4E6F86',
            lighten2:  '#345A74',
            lighten1:  '#1A4562',
            darken1:   '#002B48',
            darken2:   '#002640',
            darken3:   '#002138',
            darken4:   '#001C30',
        },
        secondary: {
            base: '#C8004B',
            lighten5:  '#E682A5',
            lighten4:  '#E06893',
            lighten3:  '#D85586',
            lighten2:  '#D4346F',
            lighten1:  '#CE1A5D',
            darken1:   '#B40043',
            darken2:   '#A0003B',
            darken3:   '#8C0033',
            darken4:   '#78002B'
        },

}
  
