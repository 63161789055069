<template>
  <v-app>
    <core-app-bar />

    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>

    <core-footer />
  </v-app>
</template>

<script>
import { version } from "../package";
export default {
  name: "App",
  data: () => ({
    appVersion: version,
  }),
  components: {
    CoreAppBar: () => import("@/components/core/AppBar"),
    coreFooter: () => import("@/components/core/Footer"),
  },
  mounted() {
    console.log("ver.: ", this.appVersion);
  },
};
</script>
