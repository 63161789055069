import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import store from './store/index';
import i18n from './i18n';
import Keycloak from '../node_modules/keycloak-js/';
import EnvProvider from 'jvjr-docker-env';

Vue.config.productionTip = false

fetch(process.env.BASE_URL + "denabler-config.json")
  .then((response) => {
    response.json().then((config) => {
      Vue.prototype.$deConfig = config
      store.state.serverlessModule.serverless_config.apihost = EnvProvider.value('SERVERLESS_APIHOST')
      store.state.serverlessModule.serverless_config.api_key = EnvProvider.value('SERVERLESS_API_KEY')
      
      const KCinitOptions = {
          url: EnvProvider.value('KEYCLOAK_INIT_URL'),
          realm: EnvProvider.value('KEYCLOAK_REALM'),
          clientId: EnvProvider.value('KEYCLOAK_CLIENTID'),
          onLoad: EnvProvider.value('KEYCLOAK_ONLOAD')
      }
      store.state.loginModule.keycloak_config = KCinitOptions
      const keycloak = new Keycloak(KCinitOptions)

      keycloak.init({ onLoad: KCinitOptions.onLoad, promiseType: 'native', checkLoginIframeInterval: 0 })
        .then((auth) => {
          if (!auth) {
            window.location.reload()
          } else {
            store.state.loginModule.token = keycloak.token
      
            keycloak.loadUserProfile().then(function (profile) {
              store.state.loginModule.username = profile.username
              store.state.loginModule.email = profile.email

              new Vue({
                router,
                store,
                vuetify,
                i18n,
                beforeCreate() {
                },
                created() {
                },
                beforeMount() {
                },
                mounted() {
                },
                render: h => h(App)
              }).$mount('#app')

            }).catch(function () {
              alert('Failed to load user profile')
            })
          }
      
          setInterval(() => {
            keycloak.updateToken(20000).then((refreshed) => {
              if (refreshed) {
                store.state.loginModule.token = keycloak.token
              } else {
              Vue.$log.warn('Token not refreshed, valid for ' +
                Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds')
              }
            }).catch((e) => {
              console.log(e)
            })
          }, 60000)
        }).catch((e) => {
            console.log("Keycloak Authentication error", e);
        })

    })
})