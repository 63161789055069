
export default{
    namespaced: true,

    state: () => ({
      api_key: 'no key',
      namespaces: 'no namespaces',
      username: 'no name',
      email: 'no mail',
      token: '',
      keycloak_config:''
    }),
    mutations: {
    },
    actions: {
    },
    getters: {
      getApiKey: (state) => {
        return state.api_key;
      },
      getNamespaces: (state) => {
        return state.namespaces;
      },
      getUser: (state) => {
        return state.username;
      },
      getEmail: (state) => {
        return state.email;
      },
      getKeycloakToken: (state) => {
        return state.token;
      },
      getKeycloakConfig: (state) => {
        return state.keycloak_config;
      }
    },

  }

  
