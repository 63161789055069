import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import detheme from './themes/theme'
import EnvProvider from 'jvjr-docker-env';

Vue.use(Vuetify);

// Translation provided by Vuetify (javascript)
import it from 'vuetify/es5/locale/it'
import es from 'vuetify/es5/locale/es'

export default new Vuetify({
    theme: { 
        themes: {
            light: detheme,
        },
    },
    lang: {
        locales: { it, es },
        current: EnvProvider.value('I18N_LOCALE') || "en",
    },
})
