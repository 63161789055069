export default {
    namespaced: true,
    
    state: () => ({
      serverless_config: {
        "apihost": "",
        "api_key": ""
      }
    }),
    mutations: {
    },
    actions: {
    },
    getters: {
      getServerless: (state) => {
        return state.serverless_config;
      }
    }
  }