export default {
    namespaced: true,
    
    state: () => ({
      sidedrawer: true,
      minisider: false
    }),
    mutations: {
      SET_MINISIDER(state, value){
        if(value!=undefined) return state.minisider = value
        return state.minisider = !state.minisider;
      },
      SET_SIDEDRAWER(state, value){
        if(value!=undefined) return state.sidedrawer = value
        return state.sidedrawer = !state.sidedrawer;
      }
    },
    actions: {
      setDrawer({commit}, value){
        commit('SET_SIDEDRAWER', value);
      },
      setMinisider({commit}, value){
        commit('SET_MINISIDER', value);
      }
    },
    modules: {
    }
  }