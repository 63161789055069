import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    name: 'Functions',
    path: '/',
    component: () => import('../views/Functions.vue')
  },
  {
    name: 'Function details',
    path: '/functions/:id',
    component: () => import('../views/pages/FunctionDetails.vue')
  },
  {
    name: 'Choose a new language for the function',
    path: '/functions/runtimes/',
    component: () => import('../views/pages/FunctionsCreateLanguage.vue'),
  },
  {
    name: 'Create new function form',
    path: '/functions/new',
    component: () => import('../views/pages/FunctionsCreateForm.vue'),
  }
]

const router = new VueRouter({
  history: true,
  mode: 'hash',
  routes
})

export default router
