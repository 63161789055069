import Vue from 'vue'
import Vuex from 'vuex'

import drawerSidebarModule from '@/store/modules/drawerSidebar.js';
import loginModule from '@/store/modules/login.js';
import serverlessModule from '@/store/modules/serverless.js';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    drawerSidebarModule,
    loginModule,
    serverlessModule,
  }
});
